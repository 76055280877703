import type { AuthMFAGetAuthenticatorAssuranceLevelResponse, Session } from '@supabase/supabase-js';
import { FilterValue } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import store2 from 'store2';
import { create } from 'zustand';

import { ProfileInfo } from '@/types/user';

type ClientShiftTableFilters = Record<
  'status' | 'client_site_template_id' | 'discipline',
  FilterValue | null
>;
type ClientShiftTableQueryParams = {
  start_date: string;
  end_date: string;
  client_site_codes?: string[];
  client_site_department_codes?: string[];
};
export type ClientHistoryTableQueryParams = {
  start_date: string;
  end_date: string;
  agency_ids: number[];
  client_site_codes?: string[];
  client_site_department_codes?: string[];
  statuses?: string[];
};

type ClientHistoryFilters = Record<'status', FilterValue | null>;
type ClientUserState = {
  pages: {
    overview: {
      selectedSite: string;
      selectedDepartment: string;
      selectedDate: dayjs.Dayjs;
      filters: ClientShiftTableFilters;
    } & {
      setSelectedSite: (selectedSite: string) => void;
      setSelectedDepartment: (selectedDepartment: string) => void;
      setSelectedDate: (selectedDate: dayjs.Dayjs) => void;
      setFilters: (filters: ClientShiftTableFilters) => void;
    };
    shifts: {
      queryParams: ClientShiftTableQueryParams;
      filters: ClientShiftTableFilters;
      pageNumber: number;
    } & {
      setQueryParams: (queryParams: ClientShiftTableQueryParams) => void;
      setFilters: (filters: ClientShiftTableFilters) => void;
      setPageNumber: (pageNumber: number) => void;
    };
    history: {
      queryParams: ClientHistoryTableQueryParams;
      filters: ClientHistoryFilters;
      pageNumber: number;
      period: 'dateRange' | 'dateWeekRange' | 'dateMonthRange';
    } & {
      setQueryParams: (queryParams: ClientHistoryTableQueryParams) => void;
      setFilters: (filters: ClientHistoryFilters) => void;
      setPageNumber: (pageNumber: number) => void;
      setPeriod: (period: 'dateRange' | 'dateWeekRange' | 'dateMonthRange') => void;
    };
  };
};

type AgencyShiftTableFilters = Record<
  'status' | 'site' | 'department' | 'discipline',
  FilterValue | null
>;
type AgencyShiftTableQueryParams = {
  start_date: string;
  end_date: string;
};
type AgencyUserState = {
  pages: {
    overview: {
      selectedDate: dayjs.Dayjs;
      filters: AgencyShiftTableFilters;
    } & {
      setSelectedDate: (selectedDate: dayjs.Dayjs) => void;
      setFilters: (filters: AgencyShiftTableFilters) => void;
    };
    shifts: {
      queryParams: AgencyShiftTableQueryParams;
      filters: AgencyShiftTableFilters;
      pageNumber: number;
    } & {
      setQueryParams: (queryParams: AgencyShiftTableQueryParams) => void;
      setFilters: (filters: AgencyShiftTableFilters) => void;
      setPageNumber: (pageNumber: number) => void;
    };
  };
};

type Store = {
  session: Session | null;
  setSession: (session: Session | null) => void;

  assuranceLevel: AuthMFAGetAuthenticatorAssuranceLevelResponse['data'] | null;
  setAssuranceLevel: (
    assuranceLevel: AuthMFAGetAuthenticatorAssuranceLevelResponse['data'] | null
  ) => void;

  profileInfo: ProfileInfo | null;
  setProfile: (profile: ProfileInfo | null) => void;

  clientUserState: ClientUserState;
  agencyUserState: AgencyUserState;
};

export const useAppStore = create<Store>()((set) => ({
  session: store2.get(process.env.REACT_APP_SUPABASE_SESSION_LOCAL_STORAGE) ?? null,
  setSession: (session: Session | null) => {
    if (session === null) {
      // store2.remove(process.env.REACT_APP_SUPABASE_SESSION_LOCAL_STORAGE);
    }
    set(() => ({ session: session }));
  },

  assuranceLevel: null,
  setAssuranceLevel: (
    assuranceLevel: AuthMFAGetAuthenticatorAssuranceLevelResponse['data'] | null
  ) => {
    set(() => ({ assuranceLevel: assuranceLevel }));
  },

  profileInfo: null,
  setProfile: (profileInfo: ProfileInfo | null) => {
    set(() => ({
      profileInfo: profileInfo,
    }));
  },
  clientUserState: {
    pages: {
      overview: {
        selectedSite: 'all_sites',
        setSelectedSite: (selectedSite: string) => {
          set((state) => ({
            clientUserState: {
              ...state.clientUserState,
              pages: {
                ...state.clientUserState.pages,
                overview: {
                  ...state.clientUserState.pages.overview,
                  selectedSite: selectedSite,
                },
              },
            },
          }));
        },
        selectedDepartment: 'all_departments',
        setSelectedDepartment: (selectedDepartment: string) => {
          set((state) => ({
            clientUserState: {
              ...state.clientUserState,
              pages: {
                ...state.clientUserState.pages,
                overview: {
                  ...state.clientUserState.pages.overview,
                  selectedDepartment: selectedDepartment,
                },
              },
            },
          }));
        },
        selectedDate: dayjs(),
        setSelectedDate: (selectedDate: dayjs.Dayjs) => {
          set((state) => ({
            clientUserState: {
              ...state.clientUserState,
              pages: {
                ...state.clientUserState.pages,
                overview: {
                  ...state.clientUserState.pages.overview,
                  selectedDate: selectedDate,
                },
              },
            },
          }));
        },
        filters: {
          status: null,
          client_site_template_id: null,
          discipline: null,
        },
        setFilters: (filters: ClientShiftTableFilters) => {
          set((state) => ({
            clientUserState: {
              ...state.clientUserState,
              pages: {
                ...state.clientUserState.pages,
                overview: {
                  ...state.clientUserState.pages.overview,
                  filters: filters,
                },
              },
            },
          }));
        },
      },
      shifts: {
        queryParams: {
          start_date: '',
          end_date: '',
          client_site_codes: [],
          client_site_department_codes: [],
        },
        setQueryParams: (queryParams: ClientShiftTableQueryParams) => {
          set((state) => ({
            clientUserState: {
              ...state.clientUserState,
              pages: {
                ...state.clientUserState.pages,
                shifts: {
                  ...state.clientUserState.pages.shifts,
                  queryParams: queryParams,
                },
              },
            },
          }));
        },
        filters: {
          status: null,
          client_site_template_id: null,
          discipline: null,
        },
        setFilters: (filters: ClientShiftTableFilters) => {
          set((state) => ({
            clientUserState: {
              ...state.clientUserState,
              pages: {
                ...state.clientUserState.pages,
                shifts: {
                  ...state.clientUserState.pages.shifts,
                  filters: filters,
                },
              },
            },
          }));
        },
        pageNumber: 1,
        setPageNumber: (pageNumber: number) => {
          set((state) => ({
            clientUserState: {
              ...state.clientUserState,
              pages: {
                ...state.clientUserState.pages,
                shifts: {
                  ...state.clientUserState.pages.shifts,
                  pageNumber: pageNumber,
                },
              },
            },
          }));
        },
      },
      history: {
        queryParams: {
          start_date: '',
          end_date: '',
          agency_ids: [],
          client_site_codes: [],
          client_site_department_codes: [],
          statuses: [],
        },
        setQueryParams: (queryParams: ClientHistoryTableQueryParams) => {
          set((state) => ({
            clientUserState: {
              ...state.clientUserState,
              pages: {
                ...state.clientUserState.pages,
                history: {
                  ...state.clientUserState.pages.history,
                  queryParams: queryParams,
                },
              },
            },
          }));
        },
        filters: {
          status: null,
        },
        setFilters: (filters: ClientHistoryFilters) => {
          set((state) => ({
            clientUserState: {
              ...state.clientUserState,
              pages: {
                ...state.clientUserState.pages,
                history: {
                  ...state.clientUserState.pages.history,
                  filters: filters,
                },
              },
            },
          }));
        },
        pageNumber: 1,
        setPageNumber: (pageNumber: number) => {
          set((state) => ({
            clientUserState: {
              ...state.clientUserState,
              pages: {
                ...state.clientUserState.pages,
                history: {
                  ...state.clientUserState.pages.history,
                  pageNumber: pageNumber,
                },
              },
            },
          }));
        },
        period: 'dateMonthRange',
        setPeriod: (period: 'dateMonthRange' | 'dateWeekRange' | 'dateRange') => {
          set((state) => ({
            clientUserState: {
              ...state.clientUserState,
              pages: {
                ...state.clientUserState.pages,
                history: {
                  ...state.clientUserState.pages.history,
                  period: period,
                },
              },
            },
          }));
        },
      },
    },
  },
  agencyUserState: {
    pages: {
      overview: {
        selectedDate: dayjs(),
        setSelectedDate: (selectedDate: dayjs.Dayjs) => {
          set((state) => ({
            agencyUserState: {
              ...state.agencyUserState,
              pages: {
                ...state.agencyUserState.pages,
                overview: {
                  ...state.agencyUserState.pages.overview,
                  selectedDate: selectedDate,
                },
              },
            },
          }));
        },
        filters: {
          status: null,
          site: null,
          department: null,
          time: null,
        },
        setFilters: (filters: AgencyShiftTableFilters) => {
          set((state) => ({
            agencyUserState: {
              ...state.agencyUserState,
              pages: {
                ...state.agencyUserState.pages,
                overview: {
                  ...state.agencyUserState.pages.overview,
                  filters: filters,
                },
              },
            },
          }));
        },
        shifts: {
          queryParams: {
            start_date: '',
            end_date: '',
          },
          setQueryParams: (queryParams: AgencyShiftTableQueryParams) => {
            set((state) => ({
              agencyUserState: {
                ...state.agencyUserState,
                pages: {
                  ...state.agencyUserState.pages,
                  shifts: {
                    ...state.agencyUserState.pages.shifts,
                    queryParams: queryParams,
                  },
                },
              },
            }));
          },
          filters: {
            status: null,
            site: null,
            department: null,
            time: null,
          },
          setFilters: (filters: AgencyShiftTableFilters) => {
            set((state) => ({
              agencyUserState: {
                ...state.agencyUserState,
                pages: {
                  ...state.agencyUserState.pages,
                  shifts: {
                    ...state.agencyUserState.pages.shifts,
                    filters: filters,
                  },
                },
              },
            }));
          },
          pageNumber: 1,
          setPageNumber: (pageNumber: number) => {
            set((state) => ({
              agencyUserState: {
                ...state.agencyUserState,
                pages: {
                  ...state.agencyUserState.pages,
                  shifts: {
                    ...state.agencyUserState.pages.shifts,
                    pageNumber: pageNumber,
                  },
                },
              },
            }));
          },
        },
      },
      shifts: {
        queryParams: {
          start_date: '',
          end_date: '',
        },
        setQueryParams: (queryParams: AgencyShiftTableQueryParams) => {
          set((state) => ({
            agencyUserState: {
              ...state.agencyUserState,
              pages: {
                ...state.agencyUserState.pages,
                shifts: {
                  ...state.agencyUserState.pages.shifts,
                  queryParams: queryParams,
                },
              },
            },
          }));
        },
        filters: {
          status: null,
          site: null,
          department: null,
          time: null,
        },
        setFilters: (filters: AgencyShiftTableFilters) => {
          set((state) => ({
            agencyUserState: {
              ...state.agencyUserState,
              pages: {
                ...state.agencyUserState.pages,
                shifts: {
                  ...state.agencyUserState.pages.shifts,
                  filters: filters,
                },
              },
            },
          }));
        },
        pageNumber: 1,
        setPageNumber: (pageNumber: number) => {
          set((state) => ({
            agencyUserState: {
              ...state.agencyUserState,
              pages: {
                ...state.agencyUserState.pages,
                shifts: {
                  ...state.agencyUserState.pages.shifts,
                  pageNumber: pageNumber,
                },
              },
            },
          }));
        },
      },
    },
  },
}));
