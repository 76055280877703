import {
  CalendarOutlined,
  TeamOutlined,
  BuildOutlined,
  FileExcelOutlined,
  FundProjectionScreenOutlined,
  AuditOutlined,
  DollarOutlined,
  UnorderedListOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { MenuProps, Tabs, Layout, Menu, Typography, theme } from 'antd';
import { Suspense, useMemo, useState } from 'react';
import './dashboard.css';
const { Content, Sider } = Layout;
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import HeaderMenu from '@/components/headerMenu/headerMenu';
import { ProfileInfo } from '@/types/user';

const clientSidebarItems: MenuProps['items'] = [
  {
    key: 'client/overview',
    icon: <CalendarOutlined />,
    label: 'overview',
  },
  {
    key: 'client/shifts',
    icon: <UnorderedListOutlined />,
    label: 'shifts',
  },
  {
    key: 'client/team',
    icon: <TeamOutlined />,
    label: 'team',
  },
  {
    key: 'client/agencies',
    icon: <BuildOutlined />,
    label: 'agencies',
  },
  {
    key: 'client/work-history',
    icon: <HistoryOutlined />,
    label: 'work history',
  },
  ...(process.env.REACT_APP_DISABLE_PRO
    ? []
    : [
        {
          key: 'client/trainings',
          icon: <FundProjectionScreenOutlined />,
          label: 'trainings',
        },
        {
          key: 'client/disputes',
          icon: <FileExcelOutlined />,
          label: 'disputes',
        },
      ]),
];

const contractorSidebarItems: MenuProps['items'] = [
  {
    key: 'contractor/overView',
    icon: <CalendarOutlined />,
    label: 'schedule',
  },
  {
    key: 'contractor/postings',
    icon: <UnorderedListOutlined />,
    label: 'postings',
  },
  {
    key: 'contractor/agency',
    icon: <BuildOutlined />,
    label: 'agency',
  },
  {
    key: 'contractor/hospitals',
    icon: <TeamOutlined />,
    label: 'hospitals',
  },
  {
    key: 'contractor/trainings',
    icon: <FundProjectionScreenOutlined />,
    label: 'training',
  },
  {
    key: 'contractor/disputes',
    icon: <FileExcelOutlined />,
    label: 'disputes',
  },
  {
    key: 'contractor/payments',
    icon: <DollarOutlined />,
    label: 'payments',
  },
  {
    key: 'contractor/documents',
    icon: <AuditOutlined />,
    label: 'documents',
  },
  {
    key: 'contractor/history',
    icon: <FileExcelOutlined />,
    label: 'Work history',
  },
];

const agencySidebarMenuItems = [
  {
    key: 'agency/overview',
    icon: <CalendarOutlined />,
    label: 'overview',
  },
  {
    key: 'agency/shifts',
    icon: <UnorderedListOutlined />,
    label: 'shifts',
  },
  {
    key: 'agency/client',
    icon: <BuildOutlined />,
    label: 'hospital',
  },
];

const Dashboard = ({ profileInfo }: { profileInfo: ProfileInfo }) => {
  const navigate = useNavigate();
  const { Title } = Typography;
  const {
    token: { colorTextLightSolid },
  } = theme.useToken();
  const [pageTitle, setPageTitle] = useState('');
  const [actionButtons, setActionButtons] = useState(<></>);
  const [collapsed, setCollapsed] = useState(true);
  const [manuallyTriggeredCollapse, setManuallyTriggeredCollapse] = useState(false);

  const location = useLocation();
  const membership = useMemo(() => profileInfo.memberships[0]!, [profileInfo]);
  const sideBarItems = useMemo(() => {
    switch (membership.type) {
      case 'CLIENT':
        return clientSidebarItems;
      case 'FREELANCE':
        return contractorSidebarItems;
      case 'AGENCY':
        return agencySidebarMenuItems;
      default:
        return [];
    }
  }, [membership]);

  const renderSideBar = () => {
    if (membership.status !== 'ACTIVATED') return null;
    return (
      <Sider
        theme="dark"
        width={200}
        breakpoint="lg"
        collapsedWidth={80}
        onBreakpoint={(broken) => {
          if (manuallyTriggeredCollapse) setCollapsed(broken);
        }}
        collapsed={collapsed}
      >
        <div
          style={{
            height: 60,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Title style={{ color: colorTextLightSolid }} level={3}>
            {collapsed ? 'K' : 'Kudexel'}
          </Title>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[location.pathname.slice(1)]}
          defaultOpenKeys={['sub1']}
          style={{ height: '100%', borderRight: 0 }}
          items={sideBarItems}
          onClick={({ key }) => {
            navigate(`${key.split('/')[1]}`);
          }}
        />
      </Sider>
    );
  };

  return (
    <Layout className="site-layout">
      {renderSideBar()}
      <Layout>
        <HeaderMenu
          profileInfo={profileInfo}
          collapsed={collapsed}
          onCollapseClick={() => {
            setCollapsed(!collapsed);
            setManuallyTriggeredCollapse(true);
          }}
        />
        <PageHeader
          style={{
            padding: '24px 16px 0 24px',
          }}
          title={pageTitle}
          extra={actionButtons}
        />
        <Tabs
          style={{
            padding: '0 1.5rem',
          }}
        >
          <Tabs.TabPane key={'account'} />
        </Tabs>
        <Content
          style={{
            padding: 24,
            margin: 0,
            overflow: 'auto',
          }}
        >
          <Suspense fallback={null}>
            <Outlet context={{ setPageTitle, setActionButtons, profileInfo }} />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};
export default Dashboard;
