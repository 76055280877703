import dayjs, { Dayjs } from 'dayjs';

export function calculateDisplayedDates(date: Dayjs) {
  // Determine the first day of the month
  const firstDayOfMonth = date.startOf('month');

  // Determine the last day of the month
  const lastDayOfMonth = date.endOf('month');

  // Find the weekday of the first day (0 = Sunday, 1 = Monday, etc.)
  const firstDayOfWeek = firstDayOfMonth.day();

  // Calculate how many days from the previous month are displayed in the first row
  const daysFromPreviousMonth = firstDayOfWeek;

  // Calculate how many days from the next month are displayed in the last row
  const totalDaysInCalendar = 6 * 7; // 6 rows with 7 days each
  const daysFromNextMonth = totalDaysInCalendar - (lastDayOfMonth.date() + daysFromPreviousMonth);

  // Calculate the first displayed date and the last displayed date
  const firstDisplayedDate = firstDayOfMonth
    .subtract(daysFromPreviousMonth, 'day')
    .format('YYYY-MM-DD');
  const lastDisplayedDate = lastDayOfMonth.add(daysFromNextMonth, 'day').format('YYYY-MM-DD');

  return { start_date: firstDisplayedDate, end_date: lastDisplayedDate };
}

export function calculateDisplayedDatesForPreviousMonth(date: Dayjs) {
  const firstDayOfPreviousMonth = date.subtract(1, 'month').startOf('month');
  return calculateDisplayedDates(firstDayOfPreviousMonth);
}

export function calculateDisplayedDatesForNextMonth(date: Dayjs) {
  const firstDayOfNextMonth = date.add(1, 'month').startOf('month');
  return calculateDisplayedDates(firstDayOfNextMonth);
}

export function dateDisplayedMonthRanges(date: Dayjs): { start_date: string; end_date: string }[] {
  const currMonthRange = calculateDisplayedDates(date);
  const prevMonthRange = calculateDisplayedDatesForPreviousMonth(date);
  const nextMonthRange = calculateDisplayedDatesForNextMonth(date);
  const result = [currMonthRange];
  // check if date is in the next month range
  if (
    date.isAfter(dayjs(nextMonthRange.start_date)) &&
    date.isBefore(dayjs(nextMonthRange.end_date))
  ) {
    result.push(nextMonthRange);
  }
  // check if date is in the previous month range
  if (
    date.isAfter(dayjs(prevMonthRange.start_date)) &&
    date.isBefore(dayjs(prevMonthRange.end_date))
  ) {
    result.push(prevMonthRange);
  }
  return result;
}
