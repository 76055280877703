import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { httpLink } from '@trpc/client';
import { ConfigProvider, Spin } from 'antd';
import en_US from 'antd/locale/en_US';
import * as React from 'react';
import { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import store2 from 'store2';

import { trpc } from '../utils/trpc';

function AppProvider({ children }: React.PropsWithChildren) {
  const supabaseApiURL = `https://${process.env.REACT_APP_SUPABASE_REFERENCE_ID}.supabase.co/functions/v1/api`;

  React.useEffect(() => {
    const fetchAuthCookie = async () => {
      try {
        setTrpcClient(
          trpc.createClient({
            links: [
              // create a custom ending link
              (runtime) => {
                return (ctx) => {
                  const { op } = ctx;
                  const link = httpLink({
                    url: supabaseApiURL,
                    // You can pass any HTTP headers you wish here
                    async headers() {
                      return {
                        Authorization: `Bearer ${
                          store2.get(process.env.REACT_APP_SUPABASE_SESSION_LOCAL_STORAGE)
                            ?.access_token
                        }`,
                        ['Refresh-Token']: store2.get(
                          process.env.REACT_APP_SUPABASE_SESSION_LOCAL_STORAGE
                        )?.refresh_token,
                      };
                    },
                  })(runtime);

                  return link({
                    ...ctx,
                    op: {
                      ...op,
                      path: `${op.path}`,
                    },
                  });
                };
              },
            ],
          })
        );
      } catch (err) {
        console.error(err);
      }
    };
    fetchAuthCookie();
  }, [supabaseApiURL]);

  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient, setTrpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpLink({
          url: supabaseApiURL,
          async headers() {
            return {
              authorization: `Bearer ${
                store2.get(process.env.REACT_APP_SUPABASE_SESSION_LOCAL_STORAGE)?.access_token
              }`,
            };
          },
        }),
      ],
    })
  );
  return (
    <React.Suspense fallback={<Spin />}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider locale={en_US}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <Router> {children} </Router>
          </ConfigProvider>
        </QueryClientProvider>
      </trpc.Provider>
    </React.Suspense>
  );
}
export default AppProvider;
