import { AuthMFAGetAuthenticatorAssuranceLevelResponse, Session } from '@supabase/supabase-js';
import { Spin } from 'antd';
import { Navigate, Route, Routes, useRoutes } from 'react-router-dom';

import Dashboard from '@/components/layout/dashboard/dashboard';
// import { AuthRoutes } from '@/features/auth';
import { AuthRoutes } from '@/features/auth';
import { ProfileInfo } from '@/types/user';
import { getAssuranceLevel } from '@/utils/authHelpers';
import { lazyImport } from '@/utils/lazyImport';

// Common pages
const { Disputes } = lazyImport(() => import('@/features/common/disputes'), 'Disputes');

// CLIENT PAGES
// free
const { Overview } = lazyImport(() => import('@/features/client/overview'), 'Overview');
const { Shifts } = lazyImport(() => import('@/features/client/shifts'), 'Shifts');
const { Team } = lazyImport(() => import('@/features/client/team'), 'Team');
const { Agencies } = lazyImport(() => import('@/features/client/agencies'), 'Agencies');
const { ClientUserOnboarding } = lazyImport(
  () => import('@/features/client/onboarding'),
  'ClientUserOnboarding'
);
const { WorkedShifts } = lazyImport(() => import('@/features/client/workedShifts'), 'WorkedShifts');
const { Settings } = lazyImport(() => import('@/features/auth/settings'), 'Settings');

// pro
const { Lines } = lazyImport(() => import('@/features/client/lines'), 'Lines');
const { Trainings } = lazyImport(() => import('@/features/client/trainings'), 'Trainings');

// AGENCY PAGES
const { Overview: AgencyOverview } = lazyImport(
  () => import('@/features/agency/overview'),
  'Overview'
);
const { Shifts: AgencyShifts } = lazyImport(() => import('@/features/agency/shifts'), 'Shifts');
const { ClientProfile } = lazyImport(
  () => import('@/features/agency/clientProfile'),
  'ClientProfile'
);
const { AgencyUserOnboarding } = lazyImport(
  () => import('@/features/agency/onboarding'),
  'AgencyUserOnboarding'
);

// CONTRACTOR PAGES
// const { Schedule } = lazyImport(() => import('@/features/contractor/schedule'), 'Schedule');
// const { Postings } = lazyImport(() => import('@/features/contractor/postings'), 'Postings');
// const { AgencyProfileView } = lazyImport(
//   () => import('@/features/contractor/agency'),
//   'AgencyProfileView'
// );
// const { WorkHistory } = lazyImport(() => import('@/features/contractor/history'), 'WorkHistory');
// // const { Hospitals } = lazyImport(() => import('@/features/contractor/hospitals'), 'Hospitals');
// const { PreferredSites } = lazyImport(
//   () => import('@/features/contractor/preferredSites'),
//   'PreferredSites'
// );

// COMMON PAGES
// Error Pages
const { NotFound } = lazyImport(() => import('@/features/common/errorPages'), 'NotFound');

const DashBoardRoutes = ({
  mfa,
  profileInfo,
}: {
  mfa: boolean;
  profileInfo: ProfileInfo | null;
}) => {
  if (
    !mfa ||
    !profileInfo ||
    profileInfo.status === 'PEND_REGISTRATION' ||
    profileInfo.status === 'PEND_MFA'
  ) {
    return null;
  }

  switch (profileInfo.memberships[0]!.type) {
    case 'CLIENT':
      return (
        <Routes>
          <Route path="/" element={<Dashboard profileInfo={profileInfo} />}>
            {profileInfo.memberships[0]!.status === 'PEND_ONBOARD' ? (
              <>
                <Route path="onboarding" element={<ClientUserOnboarding />} />
                <Route path="*" element={<Navigate to="/onboarding" />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Navigate to="/overview" />} />
                <Route path="overview" element={<Overview />} />
                <Route path="shifts" element={<Shifts />} />
                <Route path="agencies" element={<Agencies />} />
                <Route path="team" element={<Team />} />
                <Route path="work-history" element={<WorkedShifts />} />
                <Route path="settings" element={<Settings />} />
                <Route path="onboarding" element={<Navigate to="/overview" />} />
                <Route path="*" element={<NotFound />} />
                {process.env.REACT_APP_DISABLE_PRO !== 'true' && (
                  <>
                    <Route path="disputes" element={<Disputes />} />
                    <Route path="trainings" element={<Trainings />} />
                    <Route path="lines" element={<Lines />} />
                  </>
                )}
              </>
            )}
          </Route>
        </Routes>
      );
    case 'AGENCY':
      return (
        <Routes>
          <Route path="/" element={<Dashboard profileInfo={profileInfo} />}>
            {profileInfo.memberships[0]!.status === 'PEND_ONBOARD' ? (
              <>
                <Route path="onboarding" element={<AgencyUserOnboarding />} />
                <Route path="*" element={<Navigate to="/onboarding" />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Navigate to="/overview" />} />
                <Route path="onboarding" element={<Navigate to="/overview" />} />
                <Route path="overview" element={<AgencyOverview />} />
                <Route path="shifts" element={<AgencyShifts />} />
                <Route path="client" element={<ClientProfile />} />
                <Route path="onboarding" element={<AgencyUserOnboarding />} />
                <Route path="settings" element={<Settings />} />
              </>
            )}
          </Route>
        </Routes>
      );
    // case 'FREELANCE':
    //   return (
    //     <Routes>
    //       <Route path="/" element={<Dashboard profileInfo={profileInfo} />}>
    //         <Route path="/" element={<Navigate to="/overview" />} />
    //         <Route path="schedule" element={<Schedule />} />
    //         <Route path="history" element={<WorkHistory />} />
    //         <Route path="postings" element={<Postings />} />
    //         <Route path="agency" element={<AgencyProfileView />} />
    //         <Route path="hospitals" element={<PreferredSites />} />
    //         <Route path="disputes" element={<Disputes />} />
    //         <Route path="payments" element={<></>} />
    //         <Route path="trainings" element={<></>} />
    //       </Route>
    //     </Routes>
    //   );
    default:
      return <></>;
  }
};

export const AllAppRoutes = (props: {
  session: Session | null;
  assuranceLevel: AuthMFAGetAuthenticatorAssuranceLevelResponse['data'] | null;
  profileInfo: ProfileInfo | null;
  initialFetch: boolean;
}) => {
  const { assuranceLevel, profileInfo, session, initialFetch } = props;
  const loggedInWithMFA = getAssuranceLevel(assuranceLevel) === 'MFA';
  return useRoutes([
    {
      path: '/*',
      element: (
        <>
          {initialFetch ? (
            <>
              <AuthRoutes session={session} mfa={loggedInWithMFA} profileInfo={profileInfo} />
              <DashBoardRoutes mfa={loggedInWithMFA} profileInfo={profileInfo} />
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
              }}
            >
              <Spin />
            </div>
          )}
        </>
      ),
    },
  ]);
};
