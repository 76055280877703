import { message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePrevious } from '@/hooks/usePrevious';
import { getAssuranceLevel } from '@/utils/authHelpers';
import { calculateDisplayedDates } from '@/utils/calender';
import { supabase } from '@/utils/supabaseClient';
import { trpc } from '@/utils/trpc';
import { useAppStore } from '@/utils/zustand';

import { AllAppRoutes } from './protected';

export const AppRoutes = () => {
  const navigate = useNavigate();

  const currMonthRange = calculateDisplayedDates(dayjs());

  // data for client
  const { refetch: refetchClient, isFetched: clientSelfFetched } = trpc.clients.get.self.useQuery(
    undefined,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: false,
      onError: (error) => {
        message.error(error.message);
        setInitialFetch(true);
      },
    }
  );
  const { refetch: refetchTeam, isFetched: clientTeamFetched } = trpc.clients.get.members.useQuery(
    undefined,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: false,
      onError: (error) => {
        message.error(error.message);
        setInitialFetch(true);
      },
    }
  );
  const { isFetched: clientAgenciesFetched, refetch: refetchClientAgencies } =
    trpc.clients.get.agencies.useQuery(undefined, {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: false,
      onError: (error) => {
        message.error(error.message);
        setInitialFetch(true);
      },
    });
  const viewShiftsClient = trpc.clients.shift.get.byFilter.useQuery(
    { shift: { filter: { ...currMonthRange } } },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: false,
    }
  );

  // data for agency
  const clientForAgency = trpc.agency.get.clients.useQuery(undefined, {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: false,
    onSuccess: () => setInitialFetch(true),
    onError: (error) => {
      message.error(error.message);
    },
  });

  const viewShiftsAgency = trpc.agency.shift.get.byFilter.useQuery(
    {
      shift: {
        filter: {
          ...currMonthRange,
        },
      },
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: false,
    }
  );

  const { refetch: refetchAgency, isFetched: agencySelfFetched } = trpc.agency.get.self.useQuery(
    undefined,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: false,
      onError: (error) => {
        message.error(error.message);
        setInitialFetch(true);
      },
    }
  );
  const {
    data: getSelfData,
    refetch,
    isFetched: isProfileFetched,
    isFetching: isProfileFetching,
  } = trpc.user.get.self.useQuery(undefined, {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: false,
    onSuccess: (data) => {
      setProfile(data.get.user);
      if (getAssuranceLevel(assuranceLevel) === 'OFA') {
        setInitialFetch(true);
        return;
      }
      if (
        data.get.user.memberships[0]?.type === 'CLIENT' &&
        data.get.user.memberships[0]?.status === 'ACTIVATED' &&
        data.get.user.status === 'ACTIVATED'
      ) {
        refetchClient();
        refetchTeam();
        refetchClientAgencies();
        viewShiftsClient.refetch();
      } else if (
        data.get.user.memberships[0]?.type === 'AGENCY' &&
        data.get.user.memberships[0]?.status === 'ACTIVATED' &&
        data.get.user.status === 'ACTIVATED'
      ) {
        refetchAgency();
        clientForAgency.refetch();
        viewShiftsAgency.refetch();
      } else setInitialFetch(true);
    },
    onError: (error) => {
      message.error(error.message);
      setInitialFetch(true);
      setAssuranceLevel(null);
      setSession(null);
    },
  });

  const { assuranceLevel, setAssuranceLevel, setProfile, session, setSession } = useAppStore(
    (state) => ({
      session: state.session,
      assuranceLevel: state.assuranceLevel,
      profileInfo: state.profileInfo,
      setAssuranceLevel: state.setAssuranceLevel,
      setProfile: state.setProfile,
      setSession: state.setSession,
    })
  );

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (_event === 'PASSWORD_RECOVERY') navigate('update-password');
    });
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [navigate, setSession]);

  const [initialFetch, setInitialFetch] = useState(false);

  const previousAssuranceLevel = usePrevious(getAssuranceLevel(assuranceLevel));

  // check if user is logged in and perform initial fetch
  useEffect(() => {
    const loadData = async () => {
      // Check if user is logged in
      const {
        data: { session: _session },
        error,
      } = await supabase.auth.getSession();

      // user is not logged in
      if (error || !_session) {
        if (error) message.error(error.message);
        setAssuranceLevel(null);
        setProfile(null);
        setInitialFetch(true);
        return;
      }
      // if user is logged in but no assurance level, get it
      if (_session && !assuranceLevel) {
        const assurance = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
        setAssuranceLevel(assurance.data);
      }

      // if session is not null, get profile
      if (_session && !isProfileFetched && !isProfileFetching) {
        refetch();
      }

      // we just logged in via and got mfa assurance level
      // refetch
      if (
        previousAssuranceLevel !== getAssuranceLevel(assuranceLevel) &&
        getAssuranceLevel(assuranceLevel) === 'MFA'
      ) {
        setInitialFetch(false);
        refetch();
      }
    };
    loadData();
  }, [
    setAssuranceLevel,
    setProfile,
    assuranceLevel,
    refetch,
    session,
    isProfileFetched,
    isProfileFetching,
    previousAssuranceLevel,
    getSelfData,
  ]);

  const profileInfo = useMemo(() => getSelfData?.get?.user ?? null, [getSelfData]);

  // if user is client, get client data
  useEffect(() => {
    if (clientAgenciesFetched && clientSelfFetched && clientTeamFetched) {
      setInitialFetch(true);
    }
  }, [clientAgenciesFetched, clientSelfFetched, clientTeamFetched]);

  // id user is agency, get agency data
  useEffect(() => {
    if (agencySelfFetched && clientForAgency.isFetched) {
      setInitialFetch(true);
    }
  }, [initialFetch, clientSelfFetched, agencySelfFetched, clientForAgency.isFetched]);

  return (
    <AllAppRoutes
      session={session}
      assuranceLevel={assuranceLevel}
      profileInfo={profileInfo}
      initialFetch={initialFetch}
    />
  );
};
