import type { Session } from '@supabase/supabase-js';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ProfileInfo } from '@/types/user';

import { CompleteRegistration } from './CompleteRegistration';
import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';
import { UpdatePassword } from './UpdatePassword';

const NonMFARoutes = ({
  mfa,
  children,
  profileInfo,
}: {
  mfa: boolean | null;
  children: React.ReactElement;
  profileInfo: ProfileInfo | null;
}) => {
  if (mfa && profileInfo && profileInfo.status === 'ACTIVATED') {
    return <Navigate to="/overview" replace />;
  }
  return children;
};

export const AuthRoutes = ({
  session,
  mfa,
  profileInfo,
}: {
  session: Session | null;
  mfa: boolean | null;
  profileInfo: ProfileInfo | null;
}) => {
  if (profileInfo && profileInfo.status !== 'ACTIVATED') {
    return (
      <Routes>
        <Route
          path="complete-registration"
          element={
            <NonMFARoutes mfa={mfa} profileInfo={profileInfo}>
              <CompleteRegistration profileInfo={profileInfo} />
            </NonMFARoutes>
          }
        />
        <Route
          path="*"
          element={
            <NonMFARoutes mfa={mfa} profileInfo={profileInfo}>
              <Navigate to="complete-registration" />
            </NonMFARoutes>
          }
        />
      </Routes>
    );
  }
  if (mfa && profileInfo) {
    return null;
  }

  return (
    <Routes>
      <Route
        path="login"
        element={
          <NonMFARoutes mfa={mfa} profileInfo={profileInfo}>
            <Login />
          </NonMFARoutes>
        }
      />
      <Route
        path="forgot-password"
        element={
          <NonMFARoutes mfa={mfa} profileInfo={profileInfo}>
            <ForgotPassword />
          </NonMFARoutes>
        }
      />
      {session && (
        <Route
          path="update-password"
          element={
            <NonMFARoutes mfa={mfa} profileInfo={profileInfo}>
              <UpdatePassword />
            </NonMFARoutes>
          }
        />
      )}
      {!mfa && <Route path="*" element={<Navigate to="login" />} />}
    </Routes>
  );
};
