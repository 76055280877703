import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {
  ProFormText,
  LoginFormPage,
  ProFormSelect,
  ProFormInstance,
} from '@ant-design/pro-components';
import { Button, Space, message } from 'antd';
import { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Auth from '@/components/layout/auth';
import { getAssuranceLevel } from '@/utils/authHelpers';
import { supabase } from '@/utils/supabaseClient';
import { trpc } from '@/utils/trpc';
import { useAppStore } from '@/utils/zustand';

type LoginValues = {
  email: string;
  password: string;
};

enum DeliveryMethod {
  email = 'EMAIL',
  phone = 'PHONE',
}

type MFAForm = {
  deliveryMethod: DeliveryMethod;
  mfaToken: string;
};

export const Login = () => {
  const navigate = useNavigate();
  const { setAssuranceLevel, assuranceLevel } = useAppStore((state) => ({
    setAssuranceLevel: state.setAssuranceLevel,
    assuranceLevel: state.assuranceLevel,
    setProfile: state.setProfile,
  }));
  const [OTPStep, setOTPStep] = useState<boolean>(getAssuranceLevel(assuranceLevel) === 'OFA');
  const formRef = useRef<ProFormInstance<MFAForm>>();
  const generateMFAToken = trpc.user.mfa.preVerify.useMutation({
    onSuccess: () => {
      message.success('Code sent');
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const validateMFAToken = trpc.user.mfa.verify.useMutation({
    onSuccess: async (data) => {
      const { error } = await supabase.auth.setSession({
        access_token: data.get.access_token,
        refresh_token: data.get.refresh_token,
      });
      if (error) {
        message.error(error.message);
      }
      const assurance = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
      if (assurance.error) message.error(assurance.error.message);
      setAssuranceLevel(assurance.data);
      navigate('/overview');
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const handleLoginWithPassword = async (values: LoginValues) => {
    const { email, password } = values;
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      message.error('Error logging in: ' + error);
      return;
    }
    message.success('Logged in successfully');
    setOTPStep(true);
  };

  return (
    <Auth>
      {!OTPStep && (
        <LoginFormPage
          title="Account Login"
          subTitle="Simplify. Streamline. Succeed."
          logo=""
          backgroundImageUrl="https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png"
          submitter={{
            searchConfig: {
              submitText: 'Login',
            },
            submitButtonProps: {},
          }}
          onFinish={handleLoginWithPassword}
        >
          <ProFormText
            name="email"
            fieldProps={{
              size: 'large',
              prefix: <UserOutlined className={'prefixIcon'} />,
            }}
            placeholder={'email'}
            rules={[
              {
                type: 'email',
                required: true,
                message: 'email required',
              },
            ]}
          />
          <ProFormText.Password
            name="password"
            fieldProps={{
              size: 'large',
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            placeholder={'Password'}
            rules={[
              {
                required: true,
                message: 'password required',
              },
            ]}
          />
          {/* <ProFormCheckbox noStyle name="autoLogin">
              Remember me
            </ProFormCheckbox> */}
          <Link to={'/forgot-password'} style={{ float: 'right', marginBottom: 16 }}>
            Forgot password?
          </Link>
        </LoginFormPage>
      )}
      {OTPStep && (
        <LoginFormPage<MFAForm>
          initialValues={{ deliveryMethod: DeliveryMethod.email }}
          formRef={formRef}
          title="Account Login"
          subTitle="Simplify. Streamline. Succeed."
          logo=""
          backgroundImageUrl="https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png"
          submitter={{
            searchConfig: {
              submitText: 'Verify',
            },
            submitButtonProps: {
              loading: validateMFAToken.isLoading,
            },
          }}
          onFinish={async (formData) => {
            const factors = await supabase.auth.mfa.listFactors();
            if (factors.error) {
              message.error(factors.error.message);
              return;
            }
            const factor = factors.data.totp[0];
            if (!factor) {
              message.error('No factor found');
              return;
            }
            const verify = await supabase.auth.mfa.challengeAndVerify({
              factorId: factor.id,
              code: formData.mfaToken,
            });
            if (verify.error) {
              message.error(verify.error.message);
              return;
            } else message.success('MFA in successfully');
            // update assurance level
            const assurance = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
            if (assurance.error) {
              message.error(assurance.error.message);
            } else {
              setAssuranceLevel(assurance.data);
              navigate('/overview');
            }
          }}
        >
          <Button
            type="link"
            style={{ float: 'right', marginBottom: 12 }}
            onClick={() => setOTPStep(false)}
          >
            ← Back to login
          </Button>
          <Space>
            <ProFormSelect
              name="deliveryMethod"
              width={'sm'}
              label="Delivery method"
              fieldProps={{
                size: 'large',
              }}
              disabled={generateMFAToken.isSuccess}
              options={[
                {
                  value: DeliveryMethod.email,
                  label: 'email',
                },
                {
                  value: DeliveryMethod.phone,
                  label: 'phone',
                },
              ]}
              rules={[{ required: true, message: 'Please select a delivery method' }]}
            />
            <Button
              style={{
                position: 'relative',
                top: 2,
              }}
              size="large"
              loading={generateMFAToken.isLoading}
              disabled={generateMFAToken.isSuccess}
              onClick={async () => {
                const deliveryMethod = formRef.current?.getFieldValue(
                  'deliveryMethod'
                ) as MFAForm['deliveryMethod'];
                generateMFAToken.mutate({
                  route: deliveryMethod,
                });
              }}
            >
              Get code
            </Button>
          </Space>
          <ProFormText
            label="2FA Code"
            name="mfaToken"
            width={'sm'}
            fieldProps={{
              size: 'large',
            }}
            placeholder={'code'}
            rules={[
              {
                required: true,
                pattern: /^\d{6}$/,
                message: '6 digit code required',
              },
            ]}
          />
        </LoginFormPage>
      )}
    </Auth>
  );
};
