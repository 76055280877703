import { LockOutlined } from '@ant-design/icons';
import { ProFormText, LoginFormPage } from '@ant-design/pro-components';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

import Auth from '@/components/layout/auth';
import { supabase } from '@/utils/supabaseClient';

type UpdatePasswordValues = {
  password: string;
  'password-confirm': string;
};

export const UpdatePassword = () => {
  const navigate = useNavigate();

  const handleUpdatePassword = async (values: UpdatePasswordValues) => {
    const { password } = values;

    const { error } = await supabase.auth.updateUser({ password });

    if (error) {
      message.error('Error resetting password: ' + error.message);
    } else {
      message.success('Password has been reset');
      navigate('/login');
    }
  };

  return (
    <Auth>
      <LoginFormPage
        onFinish={handleUpdatePassword}
        title="Reset Password"
        subTitle="Enter your new password"
        logo=""
        backgroundImageUrl="https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png"
        submitter={{
          searchConfig: {
            submitText: 'Reset',
          },
          submitButtonProps: {},
        }}
      >
        <div>
          <ProFormText.Password
            name="password"
            fieldProps={{
              size: 'large',
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            placeholder={'Password'}
            rules={[
              {
                required: true,
                message: 'password required',
              },
            ]}
          />
          <ProFormText.Password
            name="password-confirm"
            dependencies={['password']}
            fieldProps={{
              size: 'large',
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Passwords do not match!');
                },
              }),
            ]}
          />
        </div>
      </LoginFormPage>
    </Auth>
  );
};
