import { Layout } from 'antd';

type AuthProps = {
  submitText?: string;
  title?: string;
  children?: React.ReactNode;
  // onSubmit: (values: any) => void;
};

const Auth = ({ children }: AuthProps) => {
  return (
    <Layout>
      <div style={{ backgroundColor: 'white', height: '100vh' }}>{children}</div>
    </Layout>
  );
};

export default Auth;
