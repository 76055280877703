import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Layout, Avatar, Dropdown, Typography, Space, Button, theme, message } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ProfileInfo } from '@/types/user';
import { supabase } from '@/utils/supabaseClient';

function HeaderMenu({
  profileInfo,
  collapsed = false,
  onCollapseClick,
}: {
  collapsed?: boolean;
  onCollapseClick: () => void;
  profileInfo: ProfileInfo;
}) {
  const { Header } = Layout;
  const { Text } = Typography;
  const { profile, memberships } = profileInfo;
  const membership = memberships[0];

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const queryClient = useQueryClient();

  const items: MenuProps['items'] = useMemo(() => {
    const baseItems = [
      {
        key: 'logout',
        label: (
          <Space
            style={{ width: '100%' }}
            direction={'horizontal'}
            size={8}
            onClick={async () => {
              try {
                const logout = await supabase.auth.signOut();
                if (logout.error) {
                  message.success(logout.error.message);
                } else {
                  message.success('Logged out');
                  queryClient.removeQueries();
                }
              } catch (error: any) {
                message.error(error?.message ?? error);
              }
            }}
          >
            <LogoutOutlined />
            <Text>Logout</Text>
          </Space>
        ),
      },
    ];

    if (membership.status === 'ACTIVATED') {
      baseItems.push({
        key: 'Profile',
        label: (
          <Link to={'/settings'}>
            <Space direction={'horizontal'} size={8}>
              <SettingOutlined />
              <Text>Settings</Text>
            </Space>
          </Link>
        ),
      });
    }

    return baseItems;
  }, [Text, membership.status, queryClient]); // Recompute items when user.onBoarded value changes

  return (
    <Header
      style={{
        height: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingInline: '0 24px',
        background: colorBgContainer,
      }}
    >
      {membership.status === 'ACTIVATED' ? (
        <Button
          type="text"
          onClick={onCollapseClick}
          style={{
            fontSize: '16px',
            width: 60,
            height: 60,
          }}
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        />
      ) : (
        <div />
      )}
      <Dropdown menu={{ items }}>
        <Button
          style={{
            fontSize: '16px',
            height: 60,
          }}
          type="text"
          icon={<Avatar src={'profile.avatar_url'} size={'small'} style={{ marginRight: 8 }} />}
        >
          {profile?.email}
        </Button>
      </Dropdown>
    </Header>
  );
}

export default HeaderMenu;
