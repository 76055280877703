import { LockOutlined } from '@ant-design/icons';
import { LoginFormPage, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { Alert, Button, Modal, Space, Spin, message } from 'antd';
import { TOTP } from 'otpauth';
import { useEffect, useMemo, useState } from 'react';

import Auth from '@/components/layout/auth';
import { ProfileInfo } from '@/types/user';
import { supabase } from '@/utils/supabaseClient';
import { trpc } from '@/utils/trpc';
import { useAppStore } from '@/utils/zustand';

type CompleteRegistrationProps = {
  profileInfo: ProfileInfo;
};

export const CompleteRegistration = ({ profileInfo }: CompleteRegistrationProps) => {
  const [phone, setPhone] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [termsModalVisible, setTermsModalVisible] = useState(false);
  const utils = trpc.useContext();
  const completeRegistration = trpc.user.register.useMutation({
    onSuccess: (data) => {
      message.success('Account created');

      utils.user.get.self.setData(undefined, (prevData) => {
        if (prevData) {
          return {
            ...prevData,
            get: {
              user: {
                ...prevData.get.user,
                status: data.update.user.profile.status,
              },
            },
          };
        }
        return prevData;
      });
      refetch();
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const addPhoneNumber = trpc.user.mfa.preRegister.useMutation({
    onSuccess: () => {
      message.success('Verification code sent');
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const verifyPhone = trpc.user.mfa.register.useMutation({
    onSuccess: async (data) => {
      message.success('Phone number verified');
      utils.user.get.self.setData(undefined, (prevData) => {
        if (prevData) {
          return {
            ...prevData,
            get: {
              user: {
                ...prevData.get.user,
                status: data.update.user.status,
                profile: {
                  ...prevData.get.user.profile,
                  phone: data.update.user.profile.phone,
                },
              },
            },
          };
        }
        return prevData;
      });
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const { setProfile, setAssuranceLevel } = useAppStore((state) => ({
    setProfile: state.setProfile,
    setAssuranceLevel: state.setAssuranceLevel,
  }));

  const { refetch, isRefetching } = trpc.user.get.self.useQuery(undefined, {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: false,
    onSuccess: (data) => {
      setProfile(data.get.user);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const OTPStep = useMemo(() => {
    return profileInfo.status === 'PEND_MFA';
  }, [profileInfo.status]);

  useEffect(() => {
    const unableToEnrollMFAErrorMessages = `Your account is unable to enroll into multi-factor authentication. 
    Please contact kudexel support to get this resolved.`;
    const HR_MS = 60 * 60 * 1000;

    const enrollUser = async () => {
      // 1. If user is already enrolled in MFA, do not enroll again
      const listFactors = await supabase.auth.mfa.listFactors();
      if (listFactors.error) {
        message.error(listFactors.error.message);
        return;
      } else if (listFactors.data.totp.length > 0) {
        return;
      }

      // 2. Enroll the user into 2FA
      const enroll = await supabase.auth.mfa.enroll({ factorType: 'totp' });
      if (enroll.error) {
        message.error(enroll.error.message);
        message.error(unableToEnrollMFAErrorMessages, HR_MS);
        return;
      }
      const secret = enroll.data.totp.secret;
      const factorId = enroll.data.id;
      const totp = new TOTP({ secret: secret, period: 30 });
      const verify = await supabase.auth.mfa.challengeAndVerify({
        factorId,
        code: totp.generate(),
      });
      if (verify.error) {
        message.error(verify.error.message);
        message.error(unableToEnrollMFAErrorMessages, HR_MS);
        return;
      }
      message.success('2FA setup complete');

      // update assurance level
      const assurance = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
      if (assurance.error) {
        message.error(assurance.error.message);
      } else {
        setAssuranceLevel(assurance.data);
      }
    };

    enrollUser();
  }, [setAssuranceLevel]);

  const renderRegistrationForm = () => {
    return (
      <LoginFormPage
        title="Create new account"
        subTitle="You must complete registration to access the platform"
        logo=""
        submitter={{
          render: (props) => {
            return [
              <Button
                loading={completeRegistration.isLoading}
                style={{
                  width: '100%',
                }}
                size="large"
                type="primary"
                key="login"
                onClick={() => {
                  props.submit();
                }}
              >
                Sign up
              </Button>,
            ];
          },
        }}
        onFinish={async (values) => {
          completeRegistration.mutate({
            name: values.first_name,
            surname: values.last_name,
            password: values.password,
            tos_version: 1,
          });
        }}
        backgroundImageUrl="https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png"
      >
        <ProFormText
          label="First name"
          name="first_name"
          placeholder={'First name'}
          fieldProps={{ size: 'large' }}
          rules={[
            {
              required: true,
            },
          ]}
        />
        <ProFormText
          label="Last name"
          name="last_name"
          placeholder={'Last name'}
          fieldProps={{ size: 'large' }}
          rules={[
            {
              required: true,
            },
          ]}
        />
        <ProFormText.Password
          label="Password"
          name="password"
          fieldProps={{
            size: 'large',
            prefix: <LockOutlined className={'prefixIcon'} />,
          }}
          placeholder={'New password'}
          rules={[
            {
              min: 8,
              required: true,
            },
          ]}
        />
        <ProFormText.Password
          label="Confirm Password"
          dependencies={['password']}
          name="password-confirm"
          fieldProps={{
            size: 'large',
            prefix: <LockOutlined className={'prefixIcon'} />,
          }}
          placeholder={'Confirm password'}
          rules={[
            {
              min: 8,
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Passwords do not match!');
              },
            }),
          ]}
        />
        <Space direction="horizontal">
          <ProFormCheckbox.Group
            rules={[{ required: true, message: 'Please accept the terms and conditions' }]}
            name="checkbox"
            layout="vertical"
            label={
              <Button type={'link'} onClick={() => setTermsModalVisible(true)}>
                Read Privacy Policies and Terms & Conditions
              </Button>
            }
            options={['Accept Privacy Policies and Terms & Conditions']}
          />
        </Space>
        <Modal
          title="Terms and Conditions"
          open={termsModalVisible}
          onCancel={() => setTermsModalVisible(false)}
          footer={null}
        >
          <p>Here are the terms and conditions:</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Modal>
      </LoginFormPage>
    );
  };
  const renderMFASetupForm = () => {
    return (
      <LoginFormPage
        title="Add phone number"
        subTitle="Add a phone number to your account"
        logo=""
        backgroundImageUrl="https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png"
        submitter={{
          render: (props) => {
            return [
              <Button
                key="confirmOTP"
                size="large"
                type="primary"
                onClick={() => {
                  props.submit();
                }}
                loading={verifyPhone.isLoading}
              >
                Verify Code
              </Button>,
            ];
          },
        }}
        onFieldsChange={(changedFields) => {
          changedFields.forEach((field) => {
            if ('phone' === (field.name as string[])[0]) {
              setIsPhoneValid(field.errors?.length === 0);
              setPhone(field.value);
            }
          });
        }}
        onFinish={async (formData) => {
          verifyPhone.mutate({ token: formData.otpCode });
        }}
      >
        <Space size={[0, 16]} direction="vertical" style={{ width: '100%' }}>
          <Alert
            showIcon
            message="You must add a phone number for 2-factor authentication before you can access your account."
            type="info"
          />
          <Space>
            <ProFormText
              addonBefore={'+1'}
              tooltip={'No special characters. Example: 6471112222 '}
              name="phone"
              fieldProps={{ size: 'large' }}
              disabled={addPhoneNumber.isSuccess}
              label="Phone number"
              placeholder="Phone number"
              rules={[
                {
                  required: true,
                  pattern: /^\d{10}$/,
                  message: 'Enter a valid phone number.',
                },
              ]}
            />
            <Button
              style={{
                position: 'relative',
                top: 2,
              }}
              size="large"
              name="getCode"
              loading={addPhoneNumber.isLoading}
              onClick={() => {
                addPhoneNumber.mutate({
                  phone: `+1${phone}`,
                });
              }}
              disabled={!isPhoneValid || addPhoneNumber.isSuccess}
            >
              Get code
            </Button>
          </Space>
        </Space>
        <ProFormText
          width={'xs'}
          name="otpCode"
          label="Verification code"
          fieldProps={{
            size: 'large',
          }}
          placeholder={'code'}
          rules={[
            {
              required: true,
            },
          ]}
        />
      </LoginFormPage>
    );
  };

  return (
    <Spin spinning={isRefetching}>
      <Auth>{OTPStep ? renderMFASetupForm() : renderRegistrationForm()}</Auth>
    </Spin>
  );
};
