import { UserOutlined } from '@ant-design/icons';
import { ProFormText, LoginFormPage } from '@ant-design/pro-components';
import { Col, Row, message } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import Auth from '@/components/layout/auth';
import { supabase } from '@/utils/supabaseClient';

type ForgotPasswordValues = {
  email: string;
};

export const ForgotPassword = () => {
  const [resetSent, setResetSent] = useState(false);
  const handleForgotPassword = async (values: ForgotPasswordValues) => {
    const { email } = values;
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${process.env.REACT_APP_DOMAIN}/update-password`,
    });
    if (error) {
      message.error('Error sending reset link: ' + error.message);
    } else {
      setResetSent(true);
      message.success('Reset link sent. Please check your email.');
    }
  };

  return (
    <Auth>
      <LoginFormPage
        onFinish={handleForgotPassword}
        title="Forgot Password"
        subTitle="Enter your email to receive a password reset link"
        logo=""
        backgroundImageUrl="https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png"
        submitter={{
          searchConfig: {
            submitText: 'Send reset link',
          },
          submitButtonProps: {
            disabled: resetSent,
          },
        }}
      >
        <Col>
          <Row>
            <Link to={'/login'} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              ← Back to login
            </Link>
          </Row>
        </Col>
        <ProFormText
          name="email"
          fieldProps={{
            size: 'large',
            prefix: <UserOutlined className={'prefixIcon'} />,
            onChange: () => {
              setResetSent(false);
            },
          }}
          placeholder={'email'}
          rules={[
            {
              type: 'email',
              required: true,
              message: 'email required',
            },
          ]}
        />
      </LoginFormPage>
    </Auth>
  );
};
